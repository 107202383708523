.twitter-picker {
  @apply !shadow-none;
}
.twitter-picker > div:last-child {
  @apply !p-0;
}
.twitter-picker > div:last-child input {
  @apply !h-[30px];
}
.circle-picker {
  @apply !w-2/3;
}
.circle-picker > span > div {
  @apply !h-7 !w-7 !mr-2 !mb-2;
}
.circle-picker > span > div > span > div {
  @apply !rounded;
}
.block-picker {
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px !important;
}
.rdw-editor-wrapper {
  @apply border rounded-lg overflow-hidden;
}
.rdw-editor-wrapper .rdw-editor-toolbar {
  @apply border-0 border-b;
}
.rdw-editor-wrapper .rdw-editor-main {
  @apply overflow-hidden;
}
.rdw-editor-wrapper .rdw-editor-main .DraftEditor-root {
  @apply py-2 px-4 h-56 overflow-auto box-content;
}
.rdw-editor-wrapper .rdw-editor-main .public-DraftStyleDefault-block {
  @apply m-0;
}

/* LIST BLOCKS */
.szh-accordion {
  @apply bg-white;
}
.szh-accordion .szh-accordion__item {
  @apply relative border border-b-0 border-gray-300 first:rounded-t-lg last:border-b last:rounded-b-lg;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading {
  @apply relative bg-gray-50;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading input {
  @apply w-full text-gray-700 placeholder:text-gray-300;
}
.szh-accordion .szh-accordion__item:first-child .szh-accordion__item-heading,
.szh-accordion .szh-accordion__item:first-child .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply rounded-t-lg;
}
.szh-accordion .szh-accordion__item:last-child .szh-accordion__item-heading,
.szh-accordion .szh-accordion__item:last-child .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply rounded-b-lg;
}
.szh-accordion .szh-accordion__item .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply flex items-center justify-between w-full py-1 px-3 font-normal text-sm text-left text-gray-500 bg-gray-100 hover:text-gray-900 hover:bg-gray-100 transition-all ease-in-out duration-300;
}
.szh-accordion .szh-accordion__item.szh-accordion__item--expanded .szh-accordion__item-heading .szh-accordion__item-btn {
  @apply bg-gray-100 text-gray-900; 
}
.szh-accordion .szh-accordion__item .szh-accordion__item-content {
  @apply p-5 border-t border-gray-300 bg-transparent;
}

/* MULTIMEDIA FILTERS */
.filters-media .block.mb-3 {
 @apply mb-0; 
}
.filters-media .search-input {
  @apply flex-1;
}
.filters-media .search-input .block {
  @apply w-full;
}
.filters-media .block input[type="text"] {
  @apply w-full bg-transparent border-none pl-8 -ml-4 rounded-none focus:ring-0 focus:outline-none;
}
.filters-media .block button {
  @apply bg-white rounded-md py-1.5 shadow-sm text-xs min-w-[180px] focus:outline-none;
}
.filters-media .block ul li {
  @apply pl-4;
}
.filters-media .block ul li span.absolute {
  @apply hidden;
}
.filters-media .block ul li[aria-selected=true] {
  @apply bg-blue-600 text-white;
}
.upload-zone-buttons .outer-dropdown > div:first-child {
  @apply h-9;
}
.upload-zone-buttons .outer-dropdown .mt-2 {
  @apply mt-4;
}
.upload-zone-buttons .btn-icon {
  @apply w-auto h-full px-1.5 rounded-none hover:bg-gray-50;
}

/* MULTIMEDIA GALLERY */
.file-alternative-text:not(.edition-mode) textarea {
  @apply border-0 px-0 py-0 h-10 resize-none text-gray-500 disabled:bg-transparent placeholder:italic placeholder:text-sm placeholder:text-gray-500;
}
.file-alternative-text.edition-mode textarea {
  @apply p-2 resize-none;
}
.file-title:not(.edition-mode) textarea {
  @apply border-0 p-0 h-auto text-lg font-medium text-gray-900 resize-none disabled:bg-transparent placeholder:italic placeholder:text-lg placeholder:text-gray-500;
}
.file-title.edition-mode textarea {
  @apply p-2 resize-none;
}

/* DATE PICKER */
.react-datepicker {
  @apply border-0 shadow-xl font-poppins;
}
.react-datepicker__header {
  @apply px-4 pt-3 pb-1 bg-primary-600 text-white;
}
 /* ====== MAIN NAVIGATION ====== */
.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow, 
.react-datepicker__month-year-read-view--down-arrow, 
.react-datepicker__navigation-icon::before {
  @apply border-white transition-all duration-300 ease-in-out;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
 @apply top-3.5; 
}
 /* ====== CURRENT MONTH AND YEAR ====== */
.react-datepicker__current-month {
  @apply text-white; 
 }
 /* ====== MONTH AND YEAR PICK====== */
.react-datepicker__year-read-view--down-arrow, 
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  @apply top-1 -right-3.5 border-t border-r h-1.5 w-1.5;
}
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  @apply border rounded-none bg-white text-gray-600;
}
.react-datepicker__month-dropdown .react-datepicker__month-option,
.react-datepicker__year-dropdown .react-datepicker__year-option {
  @apply leading-5 hover:bg-gray-50
}
.react-datepicker__month-dropdown .react-datepicker__month-option.react-datepicker__month-option--selected_month,
.react-datepicker__year-dropdown .react-datepicker__year-option.react-datepicker__year-option--selected_year {
  @apply bg-gray-200 hover:bg-gray-200;
}
/* ====== WEEKDAYS NAME HEADER ====== */
.react-datepicker__day-names {
  @apply mt-2;
}
.react-datepicker__day-names .react-datepicker__day-name {
  @apply text-gray-200;
}

/* ====== TRIANGLE ====== */
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  @apply border-b-primary-600;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  @apply right-0 left-0 m-auto !transform !translate-x-0 !translate-y-[-7px];
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  @apply right-0 left-0 m-auto !transform !translate-x-0 !translate-y-[8px];
}
/* ====== CALENDAR ====== */
.react-datepicker__day, 
.react-datepicker__time-name,
.react-datepicker-year-header,
.react-datepicker-time__header {
  @apply text-gray-600 transition-all duration-300 ease-in-out;
}
.react-datepicker__day.react-datepicker__day--outside-month {
  @apply text-gray-300;
}
.react-datepicker__day.react-datepicker__day--today {
  @apply text-gray-800 font-bold;
}
.react-datepicker__day--selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, 
.react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, 
.react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, 
.react-datepicker__quarter-text--in-range, 
.react-datepicker__year-text--selected, 
.react-datepicker__year-text--in-selecting-range, 
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
  @apply bg-primary-500 !text-white rounded-md;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover, 
.react-datepicker__day--in-range:hover, 
.react-datepicker__month-text--selected:hover, 
.react-datepicker__month-text--in-selecting-range:hover, 
.react-datepicker__month-text--in-range:hover, 
.react-datepicker__quarter-text--selected:hover, 
.react-datepicker__quarter-text--in-selecting-range:hover, 
.react-datepicker__quarter-text--in-range:hover, 
.react-datepicker__year-text--selected:hover, 
.react-datepicker__year-text--in-selecting-range:hover, 
.react-datepicker__year-text--in-range:hover {
  @apply bg-indigo-600;
}
/* ====== TIME ====== */

.react-datepicker__input-time-container {
  @apply flex flex-col m-0 box-border px-6 pt-2 pb-4 clear-both;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  @apply mt-1;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  @apply ml-0 w-full;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input .react-datepicker-time__input {
  @apply w-full rounded-lg border border-slate-400 hover:border-slate-600
}


.react-datepicker__input-container input {
  @apply rounded-l-none disabled:text-slate-400 disabled:hover:border-gray-300;
}
.react-datepicker-wrapper {
  @apply max-w-[209px];
}

/* DROPZONE */
.dropzone.isDragActive {
  @apply relative before:transition-all before:duration-500 before:content-[""] before:absolute before:-inset-2 before:bg-blue-600/50 before:border-2 before:border-dashed before:rounded-xl after:content-["DragHere"] after:absolute after:text-3xl after:text-white after:font-bold after:top-28 after:left-1/2 after:-translate-x-1/2;
}
.dropzone.isDragActive section {
  @apply transition-all duration-500 opacity-20;
}

/* INPUT BUTTON */
.input-button-field > * {
  @apply flex-1;
}

/* INPUT LOADMORE */
div.input-loadmore__outer {
  @apply block relative;
}
div.input-loadmore__control {
  @apply relative w-full cursor-pointer text-sm min-h-0 rounded-lg bg-gray-50 border border-gray-300 py-2.5 pl-2.5 pr-10 text-left hover:border-gray-400 !ring-0 !outline-none aria-expanded:border-blue-700 transition-all ease-in-out duration-300;
}
div.input-loadmore__outer.input-loadmore__outer--is-multi .input-loadmore__control {
  @apply py-0.5 min-h-[42px] transition-none transition-transform;
}
div.input-loadmore__outer.input-loadmore__outer--error div.input-loadmore__control {
  @apply border-red-600 hover:border-red-600 aria-expanded:border-red-600
}
div.input-loadmore__control.input-loadmore__control--is-focused {
  @apply border-blue-700 focus-visible:border-0;
}
div.input-loadmore__control .input-loadmore__value-container {
  @apply p-0;
}
div.input-loadmore__control .input-loadmore__value-container .input-loadmore__input-container {
  @apply m-0 p-0;
}
div.input-loadmore__control .input-loadmore__indicators .input-loadmore__indicator-separator {
  @apply hidden;
}
div.input-loadmore__control .input-loadmore__indicators .input-loadmore__indicator.input-loadmore__dropdown-indicator {
  @apply hidden;
}
div.input-loadmore__menu .input-loadmore__menu-list {
  @apply rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm;
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option {
  @apply relative font-normal cursor-pointer select-none py-2 pl-10 pr-4 transition-all ease-in-out duration-300 text-gray-900;
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option.input-loadmore__option--is-selected {
  @apply font-medium bg-white; 
}
div.input-loadmore__menu .input-loadmore__menu-list .input-loadmore__option.input-loadmore__option--is-focused {
  @apply bg-blue-100 text-blue-900;
}

/* EDITOR INPUT */
.input-editor.input-editor-error .quill .ql-toolbar  {
  @apply border-x-red-600 border-t-red-600;
}
.input-editor .ql-toolbar {
  @apply rounded-t-lg bg-gray-50;
}
.input-editor.input-editor-error .quill .ql-container  {
  @apply border-x-red-600 border-b-red-600;
}
.input-editor .ql-container {
  @apply rounded-b-lg;
}
.input-editor img.ql-align-center {
  @apply mx-auto;
}
.input-editor img.ql-align-right {
  @apply ml-auto;
}

/* NUMERIC INPUT */
.custom-number-input input::-webkit-inner-spin-button,
.custom-number-input input::-webkit-outer-spin-button {
  @apply m-0 appearance-none
}
.custom-number-input input:focus, 
.custom-number-input button:focus {
  @apply !outline-none
}

/* SHORT INPUT BUTTON PAGE */
div.block.input-button div.flex.gap-4 {
  @apply gap-0 flex-row-reverse;
}
div.block.input-button div.flex.gap-4 label {
  @apply hidden
}
div.block.input-button div.flex.gap-4 .block.mb-3 {
  @apply mb-0;
}
div.block.input-button div.flex.gap-4 .block.mb-3:last-child div.input-loadmore__control {
  @apply rounded-r-none;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child {
  @apply flex-[0];
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child button.relative {
  @apply rounded-l-none border-l-0 px-5;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child button.relative span.block.truncate {
  @apply w-0 m-0;
}
div.block.input-button div.flex.gap-4 .block.mb-3:first-child div.relative ul.absolute {
  @apply right-0 min-w-[200px];
}

.textarea-auto {
  @apply min-h-[30px] overflow-hidden resize-none;
}

/* DYNAMIC CONTENT */
.dynamic-content > .flex > .block > div:first-child {
  @apply whitespace-nowrap;
}
.dynamic-content input[type=checkbox] ~ div {
  @apply mt-1 after:mt-1;
}