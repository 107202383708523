.post-editor .section-status .block.mb-3 {
  @apply mb-0 flex items-center;
}
.post-editor .section-status .block.mb-1 {
  @apply hidden mb-0;
}
.post-editor .section-status .peer:checked ~ .peer-checked\:bg-blue-600 {
  @apply bg-green-600;
}
.post-editor .section-status .peer:checked ~ .peer-checked\:bg-blue-600 + span {
  @apply text-green-600;
}

/* TOAST */
.Toastify__toast {
  @apply flex items-center w-full p-4 text-gray-500 bg-white rounded-lg shadow-2xl min-w-[400px] max-w-lg;
}
.Toastify__toast-body {
  @apply text-sm;
}
.Toastify__toast-container {
  @apply w-fit;
}
.Toastify__toast-icon {
  @apply w-8 pr-2 py-1 border-r-[1px] border-gray-200;
}
.Toastify__toast-icon svg {
  @apply h-[18px] w-[18px];
}
.Toastify__toast-body > div:last-child {
  @apply text-[14px] fill-gray-900 font-normal pr-2 pl-1;
}
.Toastify__close-button {
  @apply self-center;
}
.Toastify__close-button svg {
  @apply fill-gray-400 w-5 h-5;
}