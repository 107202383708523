.rst__virtualScrollOverride .rst__node .rst__nodeContent {
  @apply right-1;
}
.rst__virtualScrollOverride .rst__node .rst__nodeContent .rst__collapseButton {
  @apply hidden;
}
.rst__virtualScrollOverride .rst__node .rst__nodeContent .rst__expandButton {
  @apply hidden;
}
.rst__virtualScrollOverride .rst__node .rst__rowWrapper {
  @apply p-0 pb-1;
}
.rst__virtualScrollOverride .rst__node .rst__row {
  @apply rounded-lg shadow-md shadow-gray-300 border relative min-w-full w-fit;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__moveHandle {
  @apply min-w-[50px] bg-none relative border-none shadow-none bg-white rounded-tl-lg rounded-bl-lg before:h-3 before:w-5 before:border-t-2 before:border-b-2 before:border-gray-500 before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-0.5 after:w-5 after:bg-gray-500;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents {
  @apply bg-none border-none shadow-none pr-4 pl-4 rounded-tr-lg rounded-br-lg;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents .rst__rowLabel {
  @apply flex flex-col;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle {
  @apply font-poppins;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents .rst__rowLabel .rst__rowSubtitle {
  @apply text-primary-500 font-poppins whitespace-nowrap text-ellipsis max-w-xs overflow-hidden inline-block;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents .rst__rowLabel .rst__rowSubtitle span {
  @apply text-primary-500 before:content-["#"] font-poppins;
}
.rst__virtualScrollOverride .rst__node .rst__row .rst__rowContents .rst__rowToolbar {
  @apply flex items-center gap-2 py-4;
}
.rst__virtualScrollOverride .rst__placeholder {
  @apply relative w-full before:bg-none;
}
.rst__virtualScrollOverride .rst__rowLandingPad {
  @apply before:bg-transparent before:rounded-lg before:border before:border-dashed before:border-gray-400 before:absolute before:inset-0;
}
.rst__tree, .rst__tree > div {
  @apply h-full;
}
.rst__tree .rst__placeholder {
  @apply h-full w-full max-w-none before:border before:rounded-md;
}
.rst__tree .rst__placeholder.rst__placeholderLandingPad {
  @apply before:bg-gray-100 before:border before:border-dashed before:border-gray-400;
}
.rst__tree .rst__placeholder.rst__placeholderLandingPad.rst__placeholderCancelPad {
  @apply before:bg-transparent before:border before:border-dashed before:border-gray-400;
}

.basic-model .rst__tree .rst__lineBlock {
  @apply hidden;
}
.basic-model .rst__tree .rst__node > .rst__nodeContent {
  @apply !left-0;
}
.rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after {
  @apply bg-slate-400;
}